import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull02';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class PrivacyPolicy extends React.Component {

  render() {

    const genericSection01Header = {
      title: 'Privacy Policy'
    }

    return (
      <React.Fragment>
        {/* <Clients bottomDivider /> */}

        <GenericSection topDivider className="illustration-section-04">
          <div className="container-xs"><br />
            <SectionHeader data={genericSection01Header} className="center-content" /><br />
            Effective Date: 10-01-2023<br />
            <br />
            Welcome to Sodim.dev ("we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at [Insert Contact Information].
            <br />
            When you visit our website Sodim.dev (the "Website"), and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Site and our services.
            <br />
            This privacy policy applies to all information collected through our Website (such as http://www.sodim.dev), and/or any related services, sales, marketing, or events (we refer to them collectively in this privacy policy as the "Services").
            <br /><br />
            1. What Information Do We Collect?<br />
            In Short: We collect personal information that you provide to us such as name, address, contact information, passwords, and security data, and payment information. We also collect certain information automatically when you use our Services.
            <br />
            a. Personal Information You Disclose to Us<br />
            We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Website, or otherwise contacting us. The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make, and the products and features you use. The personal information we collect can include the following:
            <br />
            Publicly Available Personal Information. We collect email addresses; and other similar data.<br />
            b. Information Automatically Collected<br />
            We automatically collect certain information when you visit, use, or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website, and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.
            <br /><br />
            2. How Do We Use Your Information?<br />
            In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
            <br />
            We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
            <br />
            We use the information we collect or receive:
            <br />
            To facilitate account creation and logon process.<br />
            To send administrative information to you for related to your account, our business purposes, and/or for legal reasons.<br />
            To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.<br />
            To respond to legal requests and prevent harm.<br /><br />
            3. Will Your Information Be Shared with Anyone?<br />
            In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
            <br />
            We may process or share your data that we hold based on the following legal basis:
            <br />
            Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.<br />
            Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.<br />
            Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<br />
            Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<br />
            Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.<br /><br />
            4. How Do We Handle Your Social Logins?<br />
            In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.
            <br />
            Our Website offers you the option to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.
            <br />
            We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the relevant Website. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy policy to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
            <br /><br />
            5. How Long Do We Keep Your Information?<br />
            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
            <br />
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than [Insert Period].
            <br />
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            <br /><br />
            6. How Do We Keep Your Information Safe?<br />
            In Short: We aim to protect your personal information through a system of organizational and technical security measures.
            <br />
            We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the services within a secure environment.
            <br /><br />
            7. Do We Make Updates to This Policy?<br />
            In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
            <br />
            We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
            <br /><br />
            8. How Can You Contact Us About This Policy?<br />
            If you have questions or comments about this policy, you may email us at support@sodim.dev
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;