import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull02';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class ToS extends React.Component {

  render() {

    const genericSection01Header = {
      title: 'Terms of Service'
    }

    return (
      <React.Fragment>
        {/* <Clients bottomDivider /> */}

        <GenericSection topDivider className="illustration-section-04">
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            {/* <p className="text-sm"> */}
            Welcome to Sodim.dev! These Terms of Service ("Terms") govern your access and use of the Sodim.dev website and any related services (collectively, the "Service"). Please read these Terms carefully before you start using the Service. By accessing or using the Service, you agree to be bound by these Terms and all applicable laws and regulations. If you disagree with any part of these Terms, you do not have the right to access or use the Service.
            <br /><br />
            1. Definitions
            <br />
            "Account" means your account on the Service, which may include your username, password, and other profile information.
            "Content" means any text, graphics, images, audio, video, software, code, or other data uploaded, posted, or otherwise submitted to the Service by you or other users.
            "Service" means the Sodim.dev website and any related services offered by Sodim.dev.
            "Sodim.dev" means the owner and operator of the Service.
            "User" means any person or entity that accesses or uses the Service.
            <br /><br />
            2. User Accounts
            <br />

            You may need to create an Account to access certain features of the Service. You are responsible for maintaining the confidentiality of your Account credentials and for all activities that occur under your Account. You agree to notify Sodim.dev immediately of any unauthorized use of your Account or any other security breach. Sodim.dev cannot and will not be liable for any loss or damage arising from your failure to comply with these security obligations.
            <br /><br />
            3. User Content
            <br />
            You are solely responsible for the Content that you upload, post, or otherwise submit to the Service. You represent and warrant that you own all rights to the Content or that you have the necessary permissions to use and distribute the Content. You agree that your Content will not violate any applicable law, regulation, or third-party right.
            <br />
            Sodim.dev reserves the right, but does not have the obligation, to monitor, edit, or remove any Content that it deems to be in violation of these Terms or that is otherwise objectionable.
            <br /><br />
            4. Acceptable Use
            <br />
            You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
            <br />
            To harm, threaten, abuse, or harass another person.<br />
            To impersonate any person or entity.<br />
            To interfere with or disrupt the Service or any servers or networks connected to the Service.<br />
            To use the Service for any illegal or unauthorized purpose, including but not limited to violating any intellectual property laws.<br />
            To upload, post, or otherwise submit Content that is defamatory, obscene, hateful, or discriminatory.<br />
            To collect or store personal information about other users without their consent.<br />
            <br />
            5. Intellectual Property<br />
            The Service and all Content and materials on the Service, including but
            not limited to text, graphics, logos, images, audio, video, software, and
            code, are the property of Sodim.dev or its licensors and are protected by
            copyright, trademark, and other intellectual property laws. You may access
            and use the Service for your personal, non-commercial use only. You may not
            modify, copy, distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, or otherwise exploit any Content or materials on the
            Service without the prior written consent of Sodim.dev or its licensors.<br />
            <br />
            6. Disclaimer of Warranties<br />
            THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTY
            OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            AND NON-INFRINGEMENT. SODIM.DEV DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED,
            ERROR-FREE, VIRUS-FREE, OR SECURE. SODIM.DEV SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING
            FROM THE USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES.
            <br /><br />
            7. Limitation of Liability
            <br />
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, SODIM.DEV SHALL NOT BE LIABLE FOR ANY
            DAMAGES ARISING FROM YOUR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO DIRECT,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES.
            THIS LIMITATION APPLIES EVEN IF SODIM.DEV HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br /><br />
            8. Termination
            <br />
            Sodim.dev may terminate your access to the Service at any time, for any reason, with or without notice.
            Sodim.dev may also remove or block any Content that it deems to be in violation of these Terms.
            We may terminate or suspend your account and bar access to the Service immediately,
            without prior notice or liability, under our sole discretion, for any reason whatsoever
            and without limitation, including but not limited to a breach of the Terms.
            <br /><br />
            9. Contact Us
            If you have any questions about these Terms, please contact us at support@sodim.dev.

            {/* </p> */}
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

export default ToS;